body {
  background-color: #eaedf4 !important;
}
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar {
  width: 8px;
  /* for vertical scrollbars */
  height: 8px;
  /* for horizontal scrollbars */
}

::-webkit-scrollbar-track {
  background: #3119621f;
}

::-webkit-scrollbar-thumb {
  background: #31196291;
}
